import React from "react"
import { Helmet } from "react-helmet"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { Link } from "gatsby"

import "../styles/style.css"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myStyle: { display: "none" },
      myTitle: "Pack Trading Group",
      myDescription:
        "Pack Trading Group is an international trading company for the health and beauty industries. We offer our clients the ability to expand into new markets. Pack Trading Group is your partner in international trade.",
    }
  }
  changeDisplay = () => {
    if (this.state.myStyle.display === "none") {
      this.setState({ myStyle: { display: "inline-block" } })
    } else {
      this.setState({ myStyle: { display: "none" } })
    }
  }
  menuOff = () => {
    if (this.state.myStyle.display !== "none") {
      this.setState({ myStyle: { display: "none" } })
    }
  }
  render() {
    return (
      <>
        <Helmet>
          <link name="favicon" href="/favicon.png" rel="shortcut icon" type="image/x-icon" />
        </Helmet>
        <GatsbySeo
          title={
            this.props.title
              ? this.props.title + " | " + this.state.myTitle
              : this.state.myTitle
          }
          description={
            this.props.description
              ? this.props.description
              : this.state.myDescription
          }
        />
        <header className="default-container" onClick={this.menuOff}>
          <div className="row-grid">
            <div className="col-grid-2">
              <div className="content-left">
                <button aria-label="menu" onClick={this.changeDisplay}>
                  <span className="icon-bars"></span>
                </button>
              </div>
            </div>
            <div className="col-grid-8">
              <div className="content-center">
                <h1>Pack Trading Group</h1>
              </div>
            </div>
            <div className="col-grid-2">
              <div className="content-right">
                <Link aria-label="Home" to="/">
                  <span className="icon-home"></span>
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="sidebar" id="sidebarLeft" style={this.state.myStyle}>
          <ul>
            <li>
              <Link aria-label="Home" to="/">
                <span className="icon-home"></span>Home
              </Link>
            </li>
            <li>
              <Link aria-label="About" to="/about/">
                <span className="icon-book"></span>About Us
              </Link>
            </li>
            <li>
              <Link aria-label="Products" to="/products/">
                <span className="icon-shopping-bag"></span>Products
              </Link>
            </li>
            <li>
              <Link aria-label="Partners" to="/partners/">
                <span className="icon-user-plus"></span>Partners
              </Link>
            </li>
            <li>
              <Link aria-label="Contact" to="/contact/">
                <span className="icon-envelope"></span>Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <main onClick={this.menuOff}>{this.props.children}</main>
        <footer className="footer-home default-container">
          <div className="by">
            <p>
              &copy; 2020 Pack Trading Group, Inc. |{" "}
              <Link to="/privacy/">Privacy Policy</Link>
            </p>
          </div>
        </footer>
      </>
    )
  }
}

export default Layout
